import { showMessage } from 'app/store/fuse/messageSlice';
import format from 'date-fns/format';
import _ from '@lodash';
import { styled } from '@mui/material/styles';
import FusePageCarded from '@fuse/core/FusePageCarded';
import FusePageSimple from '@fuse/core/FusePageSimple/FusePageSimple';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import authTypes from 'app/auth/authTypes';
import authRoles from 'app/auth/authRoles';
import * as yup from 'yup';
import config from '../../../../config'

export default function dispatchMessage(
  dispatch,
  message,
  type = 'info',
  autoHideDuration = 3000,
  position = 'top'
) {
  dispatch(
    showMessage({
      message,
      variant: type,
      autoHideDuration,
      anchorOrigin: {
        vertical: position,
        horizontal: 'right',
      },
    })
  );
}


export function formatDateString(value, dFormat = 'MMM dd, yyyy') {
  return value ? format(new Date(value), dFormat) : '';
}

export function selectAndFormatAmount(value, value2,) {
  const amount = value > 0 ? value : value2;
  return `$${amount || '0.00'}`;
}

// It will return as "0" or "1". Converting like this like since sometime backend excepts "0" or "1"
// If we pass value as "true" first it will change to number value 1 then it will return "1"
export const getNumberBooleanString = (value) => JSON.stringify(Number(value));

// It will return true or false
// From Backend we get "0" or "1". To avoid assignment error we have to change it to boolean
// Number(value) > 0 condition checked since some of the item have value 2
export  const getBooleanValue = (value) => value === 'true' || value === '1' || value === true || Number(value) > 0;

export function calculateWeight(isManage, length, width, height, setValue) {
  if (isManage) {
    const weight = _.round(length * width * height * 0.053 * 2.22);
    if (setValue) {
      setValue('weight', weight)
    } else {
      return weight
    }
  }
  return 0
}

export function checkNonEmptyOrNull(value) {
  return value === "0" || value === 0 || value === null || typeof value === "undefined" || value === "";
}

export function selectAndFormatWeight(val1, val2) {
  const weight = val1 > 0 ? val1 : val2;
  return `${weight || '0'} lbs`
}

export function emptySchema() {
  return yup.object().shape({});
}

export function isCustomer(role) {
  return role === 'customer';
}
export function isSupplier(role) {
  return role === 'supplier';
}

export function isAnyCustomerRole(role) {
  return authRoles.customer.includes(role)
}

export function isSuppllierArtDesign(user) {
  if (!isSupplier(user.role)) return false
  const supplierTypes = user?.supplier?.supplier_types || []
  return authTypes.onlyArtDesign.some(str1 => supplierTypes.includes(str1))
}

export function isSupplierStone(user) {
  if (!isSupplier(user.role)) return false
  const supplierTypes = user?.supplier?.supplier_types || []
  return authTypes.onlyStone.some(str1 => supplierTypes.includes(str1))
}

export function isSupplierEtching(user) {
  if (!isSupplier(user.role)) return false
  const supplierTypes = user?.supplier?.supplier_types || []
  return authTypes.onlyEtching.some(str1 => supplierTypes.includes(str1))
}

export function isSupplierSandblasting(user) {
  if (!isSupplier(user.role)) return false
  const supplierTypes = user?.supplier?.supplier_types || []
  return authTypes.onlyEtching.some(str1 => supplierTypes.includes(str1))
}

export function isDefaultSupplierTypes(user) {
  if (!isSupplier(user.role)) return false
  const supplierTypes = user?.supplier?.supplier_types || []
  return authTypes.defaultSupplierTypes.some(str1 => supplierTypes.includes(str1))
}

export function maxNumeric(value, maxLength, specialCharacter, field) {
  value = value.replace(new RegExp(`[^0-9${specialCharacter}]`, 'g'), '');
  if (value.length <= maxLength) {
    field.onChange(value)
  }
}

export const AuraPageCardedRoot = styled(FusePageCarded)({
  '& .FusePageCarded-header': {},
  '& .FusePageCarded-toolbar': {},
  '& .FusePageCarded-content': {},
  '& .FusePageCarded-sidebarHeader': {},
  '& .FusePageCarded-sidebarContent': {},
});

export function formatCustomer(customer) {
  let displayName = customer.company_name || '';
  displayName += customer.first_name || customer.last_name ? '-' : '';
  displayName += `${customer.first_name} ` || '';
  displayName += customer.last_name || '';
  return displayName;
}

export function formatName(firstName, lastName) {
  return `${firstName || ''} ${lastName || ''}`;
}

export function getOrderTotalWeight(orders = []) {
  return _.sumBy(getOrderSummary(orders), (order) => {
    return order?.totalWeight;
  });
}
export function getContainerOrderTotalWeight(orders = []) {
  return _.sumBy(getContainerOrderSummary(orders), (order) => {
    return order?.totalWeight;
  });
}

export function getFilteredSplitOrders(orders = [], splitOrders = []) {
  const orderDetails = []
  if (orders?.length) {
    orders.forEach(o => orderDetails.push(...o.order_detail.filter(od => od.is_selected)))
  }
  return _.filter(splitOrders, function (item) {
    if (orderDetails.length) {
      return orderDetails.find(od => od?.id === item?.id) && item?.quantity > 0
    }
    return false
  });
}

export function getOrderSummary(orders, customer) {
  return _.map(orders, (order) => {
    const totalWeight = _.sumBy(order.order_detail, (detail) => {
      const qty = detail?.newQuantity ?? detail.selected_quantity
      return detail.is_selected ? _.multiply(qty, detail.weight) : 0
    });
    const totalQuantity = _.sumBy(order.order_detail, (detail) => {
      const qty = detail?.newQuantity ?? detail.selected_quantity
      return qty;
    });
    const selected = _.some(order.order_detail, 'is_selected');
    // const selected = order.order_detail?.filter(detail => detail.is_selected === 1)
    return {
      id: order.id,
      totalWeight,
      totalQuantity,
      selected,
      customerId: customer?.id,
      customerName: customer?.name,
    };
  });
}

export function getContainerOrderSummary(orders, customer) {
  return _.map(orders, (order) => {
    const totalWeight = _.sumBy(order.order_detail, (detail) => {
      const qty = detail?.quantity ?? detail.selected_quantity
      return detail.is_selected ? _.multiply(_.toNumber(qty), _.toNumber(detail.weight)) : 0
    });
    const totalQuantity = _.sumBy(order.order_detail, (detail) => {
      const qty = detail?.quantity ?? detail.selected_quantity
      return _.toNumber(qty);
    });
    const selected = _.some(order.order_detail, 'is_selected');
    // const selected = order.order_detail?.filter(detail => detail.is_selected === 1)
    return {
      id: order.id,
      totalWeight,
      totalQuantity,
      selected,
      customerId: customer?.id,
      customerName: customer?.name,
    };
  });
}

export const calculateunitPrice = (tariff, shipping, fob, margin, weight) => {
  if (
    tariff &&
    shipping &&
    fob &&
    margin &&
    weight &&
    tariff > 0 &&
    shipping > 0 &&
    fob > 0 &&
    margin > 0 &&
    weight > 0
  ) {
    const shipVal = Number(weight) * Number(shipping);
    const tariffVal = Number(fob) * Number(tariff / 100);
    const value = (Number(fob) + shipVal + tariffVal) / (1 - Number(margin / 100));
    const unitPrice = value && Number(value) ? value.toFixed(0) : 0;
    return unitPrice
  }

  return 0
}

export const AuraSimplePageRoot = styled(FusePageSimple)(({ theme }) => ({
  '& .FusePageSimple-header': {
    backgroundColor: theme.palette.background.paper,
    borderBottomWidth: 1,
    borderStyle: 'solid',
    borderColor: theme.palette.divider,
  },
  '& .FusePageSimple-toolbar': {},
  '& .FusePageSimple-content': {},
  '& .FusePageSimple-sidebarHeader': {},
  '& .FusePageSimple-sidebarContent': {},
}));

export const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

export const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

export const mapParams = (keys, removeKeys = []) => {
  const mapperStrings = {
    'App-Pagination-Num': 'pageNo',
    'App-Pagination-Limit': 'limit',
    'App-Pagination-Sort': 'sort'
  }
  const searchParams = new URLSearchParams(window.location.search);
  const keyValues = Object.fromEntries(searchParams);
  const obj = { ...keyValues }
  Object.keys(keys).forEach((key) => {
    const value = mapperStrings[key];
    if (value)
      obj[value] = keys[key];
    else
      obj[key] = keys[key]
  });

  return _.omit(obj, removeKeys)
}

export const getParams = (goToFirstPage) => {
  const mapperStrings = {
    'pageNo': 'App-Pagination-Num',
    'limit': 'App-Pagination-Limit',
    'sort': 'App-Pagination-Sort'
  };
  const { history, location } = window
  const sp = new URLSearchParams(window.location.search);
  // Go to first page if actions done in some other page
  // This will avoid showing empty results
  if (goToFirstPage && sp.has('pageNo')) {
    sp.delete('pageNo')
    history.replaceState(null, "", sp.toString() ? `${location.pathname}?${sp.toString()}` : location.pathname);
  }
  const keys = Object.fromEntries(sp);
  const headerObj = {};
  const filterObj = {};
  Object.keys(keys).forEach((key) => {
    const value = mapperStrings[key];
    if (value)
      headerObj[value] = keys[key];
    else
      filterObj[key] = keys[key]
  });

  return { headerObj, filterObj };
}

const client = process.env.REACT_APP_CUSTOMER || 'psg'
export const getCustomerBasedProfile = config[client] || {}